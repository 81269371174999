import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login'
  }, {
    path: '/login',
    name: 'Login',
    component: () =>
      import ('../views/Login.vue')
  }, {
    path: '/review',
    name: 'Review',
    component: () =>
      import ('../views/Review.vue'),
    redirect: '/review/list',
    children: [{
      path: 'list',
      name: 'ReviewList',
      meta: {keepAlive: true},
      component: () =>
        import ('../views/List.vue')
    }, {
      path: 'info',
      name: 'ReviewInfo',
      component: () =>
        import ('../views/Info.vue')
    }]
  }
]

const router = new VueRouter({
  routes
})

export default router
