import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ViewUI from 'view-design'
import 'view-design/dist/styles/iview.css'
import './style/common.scss'

Vue.config.productionTip = false
Vue.use(ViewUI)

import mixins from './utils/mixins'
Vue.mixin(mixins);

router.afterEach((to) => {
  let top = 0
  if(to.meta.keepAlive){
    top = to.meta.scrollTop
  }
  if(document.getElementsByClassName("pageWrap").length>0){
    document.getElementsByClassName("pageWrap")[0].scrollTop = top
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')